import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import styles from './Homework.module.sass'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PinRed from '../../ListView/pin_red.png';
import PinGrey from '../../ListView/pin_grey.png'
import useUserTeams from '../../../../hooks/useUserTeam';
import { useVideoCategoriesByAction } from '../../../../hooks/useVideoCategoriesByAction';
import { deleteFavorite, addFavorite, addHomework, removeHomework } from '../../../../Redux/listViewReducer';

import {
  createFavoriteJourney,
  deleteFavoriteBySubTitle,
  createHomework,
  deleteHomework, 
  notifyHomework} from '../../../../api/api';
import { ModalAssignment } from '../../../ProfilePage/ModalWindowPopap/ModalAssignment';
import { message } from 'antd';

const Homework = ({ currentTeam }) => {
  const { data } = useSelector(state => state.listViewReducer)
  const favoriteData = useVideoCategoriesByAction('SET_MY_FAVORITE_CATEGORY_DATA').find((item) => item.actionType === 'SET_MY_FAVORITE_SUBCATEGORY_DATA').children
  const homework = data?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA').children.find((item) => item.actionType === `cat-${currentTeam.id}`);
  const { manageTeams, currentTeamStats } = useUserTeams()
  const playerID = useSelector((state) => state.playerDataReducer.playerData.id);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null)
  const [isNotified, setIsNotified] = useState(false)
  const [isNotifying, setIsNotifying] = useState(false)

  
  const checkAccess = () =>
    currentTeamStats.managers.some((element) => element.childId === playerID);
  const isPlayerManager = currentTeamStats.players.some(
    (element) => element.childId === playerID && element.role === 'player_manager',
  );
  const isManager = checkAccess();
  
  const homeworkData = useMemo(() => {
    const allHomework = data?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA').children.map((item) => item.children[0].children).flat();
    
    // Use reduce to filter out duplicate entries based on title and subTitle
    const uniqueHomework = allHomework.reduce((acc, current) => {
      const duplicate = acc.find(item => item?.title === current?.title && item?.subTitle === current?.subTitle);
      if (!duplicate) {
        return [...acc, current];
      }
      return acc;
    }, []);
  
    return uniqueHomework;
  }, [data]);

  
  const checkFavorite = useCallback(
    (_item) => {
      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })

      return favoriteCheck
    },
    [favoriteData],
  )

  const checkHomeworkWithFavorite = useCallback(
    (_item) => {

      const homeworkCheck = homeworkData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })
  
      const favoriteCheck = favoriteData.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle
        } else {
          return item.title === _item.title
        }
      })

      return manageTeams.length > 0 ? (homeworkCheck || favoriteCheck) : favoriteCheck
    },
    [favoriteData, homeworkData, manageTeams],
  )

  

  const handleFavoriteClick = useCallback(
    async (item) => {
      if (manageTeams.length > 0) {
        setSelectedItem(item)
        return
      } 
  
      if (manageTeams.length === 0) {
        if (checkFavorite(item)) {
          await deleteFavoriteBySubTitle(item).then((res) => {
            console.log(res.msg)
            dispatch(deleteFavorite(item))
          })
        } else {
          await createFavoriteJourney(item).then((res) => {
            dispatch(addFavorite(res.favorite))
          });
        }
      }
    },
    [checkFavorite, manageTeams, dispatch],
  )

  const checkHomeworkWithTeam = useCallback(
    (team, _item) => {
      const teamData = data?.find(item => item.actionType === 'SET_MY_HOMEWORK_DATA')
        ?.children.find(item => item.actionType === `cat-${team.id}`)
        ?.children[0].children;
  
      const homeworkCheck = teamData?.some((item) => {
        if (item?.subTitle !== '') {
          return item.subTitle === _item.subTitle;
        } else {
          return item.title === _item.title;
        }
      });
  
      return homeworkCheck;
    },
    [data],
  );

  const onSubmit = useCallback(async (teams, addToFavorite) => {
    // Remove homework from teams that have been unchecked
    const teamsToRemoveHomework = manageTeams.filter(team => 
      checkHomeworkWithTeam(team, selectedItem) && !teams.some(selectedTeam => selectedTeam.id === team.id)
    );

    for (const team of teamsToRemoveHomework) {
      await deleteHomework({...selectedItem, team }).then((res) => {
        console.info(res.msg);
        dispatch(removeHomework(selectedItem, team));
      });
    }

    // Add homework to newly selected teams
    for (const team of teams) {
      if (!checkHomeworkWithTeam(team, selectedItem)) {
        await createHomework({ ...selectedItem, teamId: team.id }).then((res) => {
          console.info(res.success);
          dispatch(addHomework(selectedItem, team));
        });
      }
    }
  
    // Handle favorites
    if (checkFavorite(selectedItem) === false && addToFavorite === true) {
      await createFavoriteJourney(selectedItem).then((res) => {
        dispatch(addFavorite(res.favorite));
      });
    }
  
    if (checkFavorite(selectedItem) === true && addToFavorite === false) {
      await deleteFavoriteBySubTitle(selectedItem).then((res) => {
        console.log(res.msg);
        dispatch(deleteFavorite(selectedItem));
      });
    }
  
    setSelectedItem(null);
  }, [checkFavorite, checkHomeworkWithTeam, selectedItem, dispatch, manageTeams]);


  const handleNotifyMember = async () => {
    setIsNotifying(true)
    await notifyHomework(currentTeam.id).then((res) => {
      setIsNotifying(false)
      setIsNotified(true)
      message.success('Notification sent successfully')

      console.log({ res })

      setTimeout(() => {
        setIsNotified(false)
      }, 5000);
    })
  }

  return (
    <div className={styles.homework}>
      {homework?.children.map(category => (
        <div key={category.title} className={styles.videoCategory}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: '1rem'
          }}>
            <p className={styles.videoCatogoryHeader}>{category.title}</p>
            {(isManager || isPlayerManager) && (
              <button className={styles.button} disabled={isNotifying} onClick={handleNotifyMember}>{isNotified ? 'Notification Sent' : 'Notify Members'}</button>
            )}
          </div>
          <div className={styles.videoCluster} >
          <ul>
            {category?.children?.map((child) => {
              let link = ''

              if (child?.subFolder) {
                link = child?.subFolder + '/' + child.title + '/' + true
              } else {
                link = child.actionType + '/' + child.subTitle + '/' + true
              }

              return (
                <li key={child.title} className={styles.listRow}>
                  <div className={styles.listRow}>
                    <div className={styles.listItem}>
                      <img className={styles.pin} src={(checkHomeworkWithFavorite(child) || checkFavorite(child)) ? PinRed : PinGrey} style={{ cursor: 'pointer' }} onClick={() => handleFavoriteClick(child)} />
                      <Link
                        to={link}
                        className={styles.textListItem}
                      >
                        {child.title}
                      </Link>
                    </div>
                    {/* <div className={styles.bookmarkColumn}>
                      <img
                        className={styles.bookmark}
                        src={BookmarkGrey}
                        data-subtitle={child.subTitle}
                        onClick={(e) => switchBookmark(e)}
                      />
                    </div> */}
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      ))}
      {selectedItem && (
        <ModalAssignment
          manageTeams={manageTeams}
          onClose={() => setSelectedItem(null)}
          initFav={checkFavorite(selectedItem)}
          onSubmit={onSubmit}
          selectedItem={selectedItem}
        />
      )}
    </div>
  )
}

export default Homework