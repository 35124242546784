import React, { useEffect, useState } from 'react';
import styles from './Header-eighth.module.sass';
import { Link } from 'react-router-dom';
import ImageLogin from '../../../asset/dashboard/dashboardChild/avatar.webp';
import logo from '../../../asset/header/AST_favicon.webp';
import Cookies from 'universal-cookie';
import menuBtn from '../../../asset/home/menuRed.webp';
import HeaderEightMobile from './HeaderEighthMobile/HeaderEighthMobile';
// import { HELP_CENTER_URL } from '../../../constants/const.js';
import path from '../../../config/routes.json';
import { Avatars, Role } from '../../../constants/const.js';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { setUserActiveTeams } from '../../../Redux/teamReducer.js';
import { getUserActiveTeamList } from '../../../api/teams-api.js';
import { getFavoriteJourneys, getHomework } from '../../../api/api.js';
import { setFavorites, setHomework } from '../../../Redux/listViewReducer.js';

const HeaderEighth = (props) => {
  const cookies = new Cookies();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [redirect, setRedirect] = useState('#');
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const dispatch = useDispatch()

  const logout = () => {
    cookies.set('finaleToken', ``, { path: '/' });
    cookies.set('finaleChildToken', ``, { path: '/' });
    setRedirect('https://anytime-soccer.com/');
  };


  useEffect(() => {
    if (props.playerData.id) {
      getUserActiveTeamList().then(res => responseUserTeamsHandler(res))
      getFavoriteJourneys().then((res) => dispatch(setFavorites(res.data)))
      getHomework().then((res) => dispatch(setHomework(res)))
    }
  }, [props])
  
  const responseUserTeamsHandler = (response) => {
    response.json().then((result) => {
      dispatch(setUserActiveTeams(result));
    });
  };

  const checkShieldPhoto = () => {
    let avatar = null;
    if (props.playerData.image) {
      avatar = 'image';
    } else if (props.playerData.picture) {
      avatar = 'picture';
    }
    switch (avatar) {
      case 'picture':
        return (
          <span className={styles.welcome__avatar}>
            <img
              src={Avatars[parseInt(props.playerData.picture) - 1]}
              alt="avatar"
              className={styles.avatar}
            />
          </span>
        );
      case 'image':
        return (
          <span className={styles.welcome__photo}>
            <img src={props.playerData.image} alt="avatar" className={styles.photo} />
          </span>
        );
      default:
        return (
          <span className={styles.welcome__default}>
            <img src={ImageLogin} alt="avatar" className={styles.avatar} />
          </span>
        );
    }
  };

  const handleActionClick = () => {
    if (props.role !== Role.CHILD) {
      message.warning('Only for players :)');
    } else {
      setIsOpenDropdown(!isOpenDropdown);
    }
  };

  const handleDashboardClick = (event) => {
    if (props.role !== Role.CHILD) {
      event.preventDefault();
      message.warning('Only for players :)');
    }
  };

  return (
    <>
      {mobileMenu && (
        <HeaderEightMobile
          accessAllVideos={props.playerData.accessAllVideos}
          role={props.role}
          setMobileMenu={setMobileMenu}
          logout={logout}
          mobileMenu={mobileMenu}
          redirect={redirect}
          isOpenDropdown={isOpenDropdown}
          setIsOpenDropdown={setIsOpenDropdown}
        />
      )}
      <header className={styles.header}>
        <div className={styles.menu_btn}>
          <img src={menuBtn} alt="menuButton" onClick={() => setMobileMenu(!mobileMenu)} />
        </div>
        <div className={styles.logo}>
          <img src={logo} alt="Anytime Soccer logo" className={styles.logo_img} />
          <h2 className={styles.h2}>Anytime Soccer Training</h2>
        </div>
        <nav className={styles.nav}>
          <ul>
            <li onClick={handleActionClick} className={styles.li__action}>
              Actions
              <img
                alt=" "
                src={
                  isOpenDropdown
                    ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAeGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoABQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAAEgAAAABAAAASAAAAAEAAqACAAQAAAABAAAAEaADAAQAAAABAAAACgAAAAAlUdAjAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyGlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNi4wLjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyIKICAgICAgICAgICAgeG1sbnM6ZXhpZj0iaHR0cDovL25zLmFkb2JlLmNvbS9leGlmLzEuMC8iPgogICAgICAgICA8dGlmZjpZUmVzb2x1dGlvbj43MjwvdGlmZjpZUmVzb2x1dGlvbj4KICAgICAgICAgPHRpZmY6UmVzb2x1dGlvblVuaXQ+MjwvdGlmZjpSZXNvbHV0aW9uVW5pdD4KICAgICAgICAgPHRpZmY6WFJlc29sdXRpb24+NzI8L3RpZmY6WFJlc29sdXRpb24+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgICAgIDxleGlmOlBpeGVsWERpbWVuc2lvbj4xNzwvZXhpZjpQaXhlbFhEaW1lbnNpb24+CiAgICAgICAgIDxleGlmOkNvbG9yU3BhY2U+MTwvZXhpZjpDb2xvclNwYWNlPgogICAgICAgICA8ZXhpZjpQaXhlbFlEaW1lbnNpb24+MTA8L2V4aWY6UGl4ZWxZRGltZW5zaW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KCToxHgAAALxJREFUKBVjYCAA7pjZBtw3cBDAp4wRlyRI4z/2v0eA8tpAfJHpJ7OD4oUDH7CpZ8ImCDXgAFAOZMAXINYHGngAl4swDEEyQJ+BgfECI/NfHRCNzyAUQ9ANYPrJ5Kh07NhDEI3PIHiYYDMAOQwg8v/2MzD8NwC6CiWMwIYQMgCoCQxwGcRIrAH4DGL6y/Z3A1ABOBBBfkf2AkwjMg2SRwkjjn8FDPdNrQzumdvuB7kIWTEhNkj9XQu7BpA6ADx2fbimQwHIAAAAAElFTkSuQmCC'
                    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAhGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoABQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAAEgAAAABAAAASAAAAAEAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEaADAAQAAAABAAAACgAAAABqmViRAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNi4wLjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgoZXuEHAAAAy0lEQVQoFWNkAIK7FnYNTD+YJiheOPABxCcG3DdwEPjP/nc947+/hYwgAxj+/69nYGC8wPSTyZEYg0AG/GP/t5+B4b/B//8MB5lALgDafBEkAJIAKcDnEmQDQPqYfzEHMII0QCT+HgAy9fG5CN0App/MDiCXgw0hxiBcBoD0wg3BZxA+AzAMwWYQI/OfgP9/WTaAwgwofxHmBZBaGEBxCUwQNYwYvgDFeYAYqwEgPVgNAUmgGXQV6AIbYqIfpBcFgAy6Y2YbgCKIhQMA5tiKtKeR0bwAAAAASUVORK5CYII='
                }
                className={styles.action_button}
              />
              {isOpenDropdown && (
                <ul className={styles.nested_list}>
                  <li className={styles.nested_list__item}>
                    <Link className={styles.link} to={path.dashboardChild.reports}>
                      Reports
                    </Link>
                  </li>
                  {props.playerData.accessAllVideos && (
                    <li className={styles.nested_list__item}>
                      <Link to={path.trainingGoal} className={styles.link}>
                        My Training Goal
                      </Link>
                    </li>
                  )}
                  {/* <li className={styles.nested_list__item}>
                    <Link to={HELP_CENTER_URL} target="_blank" className={styles.link}>
                      Help Center
                    </Link>
                  </li> */}
                </ul>
              )}
            </li>
            <li className={styles.li}>
              <Link
                className={styles.link}
                to={path.dashboardChild.main}
                onClick={handleDashboardClick}
              >
                Dashboard
              </Link>
            </li>
            <li className={styles.li_welcome}>
              {checkShieldPhoto()}
              <span>Welcome {props.name}</span>
            </li>
            <li onClick={logout} className={styles.pointer}>
              <a className={styles.link} href={redirect}>
                Logout
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default HeaderEighth;
