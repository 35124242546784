import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { RadioButton } from '../../../commonComponents/RadioButton/RadioButton';
import styles from './UpgradeTeam.module.sass';
import { Link } from 'react-router-dom';
import { PromoItem } from '../../../commonComponents/PromoItem/PromoItem';
import { PromoForUpgradeTeam } from '../../../../constants/PromoList.js';
import { ExtraInfo } from '../../../commonComponents/PaymentComponents/ExtraInfo/ExtraInfo';
import { SubscriptionDetailsButton } from '../SubscriptionDetails/SubscriptionDetailsButton';
import paymentSystems from '../../../../asset/cards.webp';
import { card2, card3, CARD_ELEMENT_OPTIONS } from '../../../../constants/paymentTemplate';
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useScrollToTop } from '../../../../hooks/useScrollToTop';
import { message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { parentInfo } from '../../../../api/api.js';
import { addTeamSubscription } from '../../../../api/teams-api';
import routes from '../../../../config/routes.json';
import { showErrorMessage } from '../../../../utils/throwError';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../commonComponents/Loading/Loading';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { MOBILE_DIMENSION } from '../../../../constants/const';
import { CongratulationPopup } from '../CongratulationPopup/CongratulationPopup';
import { Congratulation } from '../../../../constants/teams.js';
import { fetchSubscriptionPriceData } from '../asyncActions/subscriptionPrice';

const ORDER_NOW = 'Order Now';

export function UpgradeTeam(props) {
  const { currentTeamId } = props;
  const dispatch = useDispatch();
  const currentTeam = useSelector((state) => state.teamReducer.currentTeam);
  const childEmail = useSelector((state) => state.playerDataReducer.playerData.childEmail);
  const subscriptionPrice = useSelector(
    (state) => state.subscriptionPriceDataReducer.subscriptionPriceData,
  );
  const { width } = useWindowDimensions();
  const [checkedRadioButton, setCheckedRadioButton] = useState({});
  const [toggPassConfirmCheckState, setToggPassConfirmCheckState] = useState(false);
  const [toggShowConfirmOrStripe, setToggShowConfirmOrStripe] = useState(false);
  const [subTotalPrice] = useState(0);
  const [stopSubmitBtn, setStopSubmitBtn] = useState(false);
  const [toggSubmitBtnSpinner, setToggSubmitBtnSpinner] = useState(false);
  const [isPopupHidden, setIsPopupHidden] = useState(true);
  const [isContentLoaded, setContentLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [total, setTotal] = useState(0);
  const stripe = useStripe();
  const elements = useElements();
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 30, color: 'red', marginTop: '1.5rem', marginLeft: '9rem' }}
      spin
    />
  );

  useEffect(() => {
    dispatch(fetchSubscriptionPriceData());
  }, []);

  useEffect(() => {
    setCheckedRadioButton({
      number: `${currentTeam.maxTeamSize} players`,
      price: subscriptionPrice[`${currentTeam.maxTeamSize} players`]?.amount,
      priceId: subscriptionPrice[`${currentTeam.maxTeamSize} players`]?.id,
    });
  }, [subscriptionPrice]);

  useScrollToTop();
  useEffect(() => {
    if (currentTeam.subscription) {
      message.info({
        duration: 7,
        content:
          'If you have already had a team subscription and you would like to enlarge team size, please use link for questions. Thank you!',
      });
      setToggPassConfirmCheckState(true);
    }
    if (!isContentLoaded) {
      parentInfo(childEmail).then((response) => {
        responseParentInfoHandler(response);
      });
    } else {
      setContentLoaded(true);
      if (currentTeam.teamSize <= checkedRadioButton?.number.substring(0, 2)) {
        setTotal(checkedRadioButton?.price / 100);
      }
    }
  }, [checkedRadioButton]);

  const responseParentInfoHandler = (response) => {
    response.json().then((response) => {
      setUserData(response);
      setContentLoaded(true);
      setTotal(checkedRadioButton?.price / 100);
    });
  };

  const clearCardElements = (elementsToClear) => {
    const elementsArray = elementsToClear._elements;
    elementsArray.forEach((element) => {
      element.clear();
    });
  };

  const paymentErrorHandler = (errorMessage) => {
    setToggSubmitBtnSpinner(false);
    clearCardElements(elements);
    setToggPassConfirmCheckState(false);
    if (errorMessage) {
      message.error({
        content: errorMessage,
        duration: 6,
      });
    }
  };

  const stripeCheckoutHandler = (response) => {
    response.json().then(() => {
      setStopSubmitBtn(stopSubmitBtn);
      setToggSubmitBtnSpinner(false);
      clearCardElements(elements);
      setIsPopupHidden(false);
    });
  };

  const unitSearchHandler = async () => {
    setStopSubmitBtn(false);
    setToggShowConfirmOrStripe(!toggShowConfirmOrStripe);
    setToggPassConfirmCheckState(!toggPassConfirmCheckState);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      billing_details: {
        address: {
          country: formik.values.country,
          city: formik.values.city,
          state: formik.values.region,
          line1: formik.values.address,
          postal_code: formik.values.postalCode,
        },
        email: userData.userInfo.email,
        name: userData.userInfo.firstname,
      },
      card: elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),
    });

    const { id } = paymentMethod;

    if (!error) {
      try {
        addTeamSubscription({
          teamId: currentTeamId,
          maxTeamSize: checkedRadioButton?.number.substring(0, 2),
          paymentId: id,
          priceId: checkedRadioButton?.priceId,
        }).then((response) => {
          stripeCheckoutHandler(response);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      paymentErrorHandler(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      discountCoupon: '',
    },
    onSubmit: async () => {
      setToggSubmitBtnSpinner(true);
      unitSearchHandler();
    },
  });

  const submitCoupon = async (e) => {
    e.preventDefault();
    showErrorMessage('Sorry, now you can`t use promocode here.');
  };
  if (!isContentLoaded) return <Loading />;

  return (
    <section className={styles.upgrade_team}>
      {!isPopupHidden && (
        <CongratulationPopup
          changeChosenScreen={setIsPopupHidden}
          defaultScreen
          popupText={Congratulation.UPGRADE}
        >
          <img
            src="/img/teams/no_active_team.webp"
            style={{ width: '250px', height: 'auto' }}
            alt=""
          />
        </CongratulationPopup>
      )}
      {width >= MOBILE_DIMENSION && (
        <h2 className={styles.upgrade_team__title}>One Year All Access Subscription</h2>
      )}
      <div className={styles.container}>
        <form id="paymentForm" onSubmit={formik.handleSubmit} className={styles.payment}>
          {width < MOBILE_DIMENSION && (
            <h2 className={styles.upgrade_team__title}>
              One Year All Access
              <br />
              Subscription
            </h2>
          )}
          <h3 className={styles.payment__title}>Payment Details</h3>
          <div className={styles.info}>
            <p className={styles.info__description}>We accept the following cards:</p>
            <img
              src={paymentSystems}
              alt="Visa MasterCard Pictures"
              className={styles.info__pics}
            />
          </div>
          <div className={styles.card_details_form}>
            <label className={styles.card_label}>
              Credit Card Number
              <CardNumberElement
                id="card-element"
                options={CARD_ELEMENT_OPTIONS}
                className={styles.card_number_input}
              />
            </label>
            <label className={styles.card_label}>
              Expiration
              <CardExpiryElement
                id="card-element-2"
                options={card2}
                className={styles.card_expiry_input}
              />
            </label>
            <label className={styles.card_label}>
              CVC
              <CardCvcElement
                id="card-element-3"
                options={card3}
                className={styles.card_cvc_input}
              />
            </label>
          </div>
          <div className={styles.container_coupon}>
            <label className={styles.discount_label}>
              Discount Coupon
              <input
                className={
                  formik.touched.discountCoupon && formik.errors.discountCoupon
                    ? styles[formik.errors.discountCoupon]
                    : ''
                }
                name="discountCoupon"
                id="discountCoupon"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.discountCoupon.replace(/[\s]/g, '')}
              />
            </label>
            <button className={styles.grey_button} onClick={submitCoupon}>
              Apply
            </button>
          </div>
          <div className={styles.divTotal}>
            {subTotalPrice ? (
              <hr className={styles.totalPrice_hr} />
            ) : (
              <hr style={{ display: 'none' }} />
            )}
            <p className={styles.totalPrice}>
              <span>Total:</span>
              <span>${total ? total : 0}</span>
            </p>
            {!!subTotalPrice && (
              <div className={styles.subPrice}>
                <span className={styles.subPrice_firstspan}>
                  {subTotalPrice && 'Total with sale:'}
                </span>
                <span>{subTotalPrice && `$${subTotalPrice}`}</span>
              </div>
            )}
          </div>
          <ExtraInfo />
          {width <= MOBILE_DIMENSION && (
            <>
              {toggSubmitBtnSpinner ? (
                <Spin indicator={antIcon} />
              ) : (
                <button
                  type="submit"
                  form="paymentForm"
                  disabled={toggPassConfirmCheckState}
                  className={styles.buttonDefault}
                >
                  {ORDER_NOW}
                </button>
              )}
            </>
          )}
        </form>
        <article className={styles.count_members}>
          <h3 className={styles.count_members__title}>Number of Players</h3>
          <div className={styles.selectors}>
            {Object.entries(subscriptionPrice).map(([key, value]) => {
              return (
                <RadioButton
                  key={key}
                  radioButton={key}
                  value={value}
                  upgradeTeam={true}
                  checkedRadioButton={checkedRadioButton}
                  setCheckedRadioButton={setCheckedRadioButton}
                >
                  <span className={styles.important}>{(value?.amount / 100)?.toFixed(2)}</span>
                </RadioButton>
              );
            })}
            <Link to={routes.upgradePage} className={styles.count_members__link}>
              Upgrade my player only
            </Link>
          </div>
          <p className={styles.count_members__info}>
            Questions?
            <Link to={routes.teamDiscounts} className={styles.count_members__link}>
              Click Here
            </Link>
            <br />
            More than 35 players?
            <Link to={routes.teamDiscounts} className={styles.count_members__link}>
              Click Here
            </Link>
          </p>
        </article>
        <article className={styles.details}>
          <h3 className={styles.details__title}>Membership Details</h3>
          <ul className={styles.promo}>
            {PromoForUpgradeTeam.map((promoItem) => {
              return (
                <PromoItem listItem={promoItem} key={promoItem}>
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAMAAABlXnzoAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAG9QTFRFAAAA3Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc93Tc9YcwlYAAAACV0Uk5TAAOSvwv/xVLEM/IgH2378O791vT83A8lCWn6yAYn6stKygKZxkLU8zcAAABYSURBVHicTY5HDoAwEMR2IUPvoYcO/38jUiiJb9ZI1hBp2HHJQgCe7T4Q2B5GehexJuE0Q8CU46UogYrkp7IGGmq7h34YoaY/NS9YTXgDFNs39sOMfF7abjHeBGHgMwM3AAAAAElFTkSuQmCC"
                    alt="check"
                    className={styles.checkImg}
                  />
                </PromoItem>
              );
            })}
          </ul>
        </article>
      </div>
      {width >= MOBILE_DIMENSION && (
        <>
          {toggSubmitBtnSpinner ? (
            <Spin indicator={antIcon} />
          ) : (
            <button
              type="submit"
              form="paymentForm"
              disabled={toggPassConfirmCheckState}
              className={styles.buttonDefault}
            >
              {ORDER_NOW}
            </button>
          )}
        </>
      )}
      {width >= MOBILE_DIMENSION && <SubscriptionDetailsButton />}
    </section>
  );
}
