import mainRoute from '../mainRoute';
import Cookies from 'universal-cookie';
import path from '../config.json';
import { PARENT_TOKEN, CHILD_TOKEN } from '../constants/const';

const cookies = new Cookies();

export const profileInfoRequest = async () => {
  return await fetch(`${mainRoute.route + path.userInfoRequest}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify({
      userInfo: {
        id: '',
        email: '',
        firstname: '',
        lastname: '',
        username: '',
        status: '',
        address: '',
        country: '',
        createdAt: '',
      },
      idChild: 'all',
      childInfo: {
        id: '',
        username: '',
        screenname: '',
        firstname: '',
        status: '',
        state: '',
        createdAt: '',
        childEmail: '',
      },
    }),
  });
};

export const parentInfo = async (childEmail) => {
  return await fetch(`${mainRoute.route}${path.parentInfo}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify({ childEmail }),
  });
};

export const editUser = async (responseData) => {
  return await fetch(`${mainRoute.route + path.edituser}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify({ ...responseData }),
  });
};

export const editChildUser = async (data) => {
  return await fetch(`${mainRoute.route + path.editplayer}`, {
    method: 'POST',
    headers: {
      Authorization: cookies.get(PARENT_TOKEN),
    },
    body: data,
  });
};

export const supportRequest = async (responseData) => {
  return await fetch(`${mainRoute.route + path.support}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify({ ...responseData }),
  });
};

export const contactRequest = async (responseData) => {
  return await fetch(`${mainRoute.route + path.contactus}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: '',
    },
    body: JSON.stringify({ ...responseData }),
  });
};

export const signInRequest = async (email, password, requestPath, fieldKey) => {
  return await fetch(`${mainRoute.route + path[requestPath]}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      [fieldKey]: email,
      password: password,
    }),
  });
};

export const childInfoRequest = async (userId = undefined) => {
  let endPoint = `${mainRoute.route + path.onlyPlayerInfoRequest}`;
  if (userId) {
    endPoint += `?userId=${userId}`;
  }
  return await fetch(endPoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const teamDiscountRequest = async (responseData) => {
  return await fetch(`${mainRoute.route + path.teamdiscount}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: '',
    },
    body: JSON.stringify({ ...responseData }),
  });
};

export const checkUniqueValues = async (uniqueFields) => {
  return await fetch(`${mainRoute.route + path.unitsearch}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify(uniqueFields),
  });
};

//
export const submitPlayerData = async (data) => {
  return await fetch(`${mainRoute.route + path.sinUpStep2}`, {
    method: 'POST',
    headers: {
      authorization: cookies.get(PARENT_TOKEN),
    },
    body: data,
  });
};

// Request function get userName and Stripe Token and send to Back
export const reactivationPlayerSendInfoToBack = async (userName) => {
  return await fetch(`${mainRoute.route + path.continueSubscription}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify({
      username: userName,
    }),
  });
};

export const joinMailingRequest = async (responseData) => {
  return await fetch(`${mainRoute.route + path.joinMailingList}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...responseData }),
  });
};

export const listOfCategoriesVimeo = async (token, route) => {
  return await fetch(`${mainRoute.route + path[route]}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(token),
    },
  });
};

export const listOfVideosVimeo = async (uri, page) => {
  return await fetch(`${mainRoute.route + path.videoForChild}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify({ uri: `${uri}/videos`, page: page }),
  });
};

export const searchListOfVideosVimeo = async (
  requestedPage,
  countElementsPerPage = 25,
  searchRequestString,
) => {
  return await fetch(`${mainRoute.route}${path.search}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify({
      page: requestedPage,
      perPage: countElementsPerPage,
      substring: searchRequestString,
    }),
  });
};

export const changeWatchedToggle = async (videoLink, videoDuration) => {
  const link = videoLink.slice(18);
  const finalLink = `/videos/${link}`;
  return await fetch(`${mainRoute.route + path.viewed}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify({ uri: finalLink, timeVideo: videoDuration }),
  });
};

export const loginAsPlayer = async (userName) => {
  // ==========================change 'route'==================================
  return await fetch(`${mainRoute.route + path.loginAsPlayer}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify({ username: userName }),
  });
};

export const resetAllWatchedVideoInCategory = async (uri) => {
  // ========     ==========      ============      =============     ========
  return await fetch(`${mainRoute.route + path.deleteViewedToggle}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify({ resetCategory: [`${uri}/videos`] }),
  });
};

export const sevenDaysMoneyBack = async (typeOfCancel, reason) => {
  return await fetch(`${mainRoute.route + path.moneyBack}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify({
      why: reason,
      typeOfCancel: typeOfCancel,
    }),
  });
};

export async function stripeTokenHandler({ id, signUpData }) {
  return await fetch(`${mainRoute.route + path.signUpStep3Token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id, ...signUpData }),
  });
}

export async function stripeSubscriptionForChildren({ id, data }) {
  return await fetch(`${mainRoute.route + path.addChildrenSubscription}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id, ...data }),
  });
}

export async function checkUnicValuesInBase(emailAndUsername) {
  return await fetch(`${mainRoute.route + path.unitsearch}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ emailAndUsername }),
  });
}

export async function billingRequest() {
  return await fetch(`${mainRoute.route + path.retrievebilling}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cookies.get(PARENT_TOKEN),
    },
  });
}

export async function changeBillingRequest(values, id, stripeCus) {
  return await fetch(`${mainRoute.route + path.changebilling}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify({ values, id, stripeCus }),
  });
}
export const cancelSubscriptionForChild = async (usernameAndReasonObj) => {
  return await fetch(`${mainRoute.route + path.cancelsubscription}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify(usernameAndReasonObj),
  });
};

export const forgotPassRequest = async (values) => {
  return await fetch(`${mainRoute.route + path.restorePassEmail}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: values }),
  });
};

export const sendNewPass = async (values, token) => {
  return await fetch(`${mainRoute.route + path.newPassRequest}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password: values, recoveryToken: token }),
  });
};

export const changeEmail = async (email) => {
  return await fetch(`${mainRoute.route + path.changeemail}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(PARENT_TOKEN),
    },
    body: JSON.stringify({ email }),
  });
};

export const recoveryEmail = async (tokenFromUrl) => {
  return await fetch(`${mainRoute.route + path.changeemailback}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // 'authorization': cookies.get(PARENT_TOKEN)
    },
    body: JSON.stringify({ tokenFromUrl }),
  });
};

export const addMailChimp = async (responseData) => {
  return await fetch(`${mainRoute.route + path.addmail}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: '',
    },
    body: JSON.stringify({ ...responseData }),
  });
};

/** This request creates new training plan or replaced current training plan
 * based on input user data / CreateTrainingGoalForm */
export const createNewTrainingPlan = async (data) => {
  return await fetch(`${mainRoute.route}${path.goal}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

/** This request uses for getting created training plan
 * Data uses in training plan component */
// запрос для получения нового тренировочного плана
export const getTrainingPlan = async (userId = undefined) => {
  let endPoint = `${mainRoute.route}${path.goal}`;
  if (userId) {
    endPoint += `?userId=${userId}`;
  }
  const res = await fetch(endPoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
  return res;
};

/** This request uses for getting closed goals list,
 * based on got data we form content of reward center component */
export const getChildAwardList = async (numberOfPage = 1, userId = undefined) => {
  let endPoint = `${mainRoute.route}${path.certificationCenter}?page=${numberOfPage}`;
  if (userId) {
    endPoint += `&userId=${userId}`;
  }
  return await fetch(endPoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

// goal/certificate/11 (скачивание сертификата, path параметром передается id сертификата)
export const downloadRewardCertificate = async (certificateId) => {
  return await fetch(`${mainRoute.route}${path.downloadCertificate}/${certificateId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

// weak streak запрос
export const getWeeklyStreak = async () => {
  return await fetch(`${mainRoute.route}${path.weeklyStreak}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const adjustWatchedVideoCount = async (data) => {
  return await fetch(`${mainRoute.route}${path.watchedVideoCountAdjustment}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
};

export const activateTrialPeriod = async () => {
  return await fetch(`${mainRoute.route}${path.activateFreeTrial}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(PARENT_TOKEN),
    },
  });
};

export const getFreeTrialDaysLeft = async () => {
  return await fetch(`${mainRoute.route}${path.getFreeTrialDaysLeft}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(PARENT_TOKEN),
    },
  });
};

export const getAllTimeViewedVideo = async (userId = undefined) => {
  let endPoint = `${mainRoute.route}${path.allTimeViewedVideo}`;
  if (userId) {
    endPoint += `?userId=${userId}`;
  }
  return await fetch(endPoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

export const getReportsData = async (year = new Date().getFullYear(), userId = undefined) => {
  let endPoint = `${mainRoute.route}${path.reports}${year}`;
  if (userId) {
    endPoint += `&userId=${userId}`;
  }
  return await fetch(endPoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
};

/**
 *  POST request with the following body:
 *  {
 *    "actionTypeCategory": string
 *  }
 * 
 *  returns:
*     {
        "data": {
          "id": 2,
          "childId": 3,
          "updatedAt": "2023-05-19T18:56:10.398Z",
          "createdAt": "2023-05-19T18:56:10.398Z"
        }
      }
 */
export const createVideoCategoryBookmark = async (data) => {
  const result = await fetch(`${mainRoute.route}${path.videoCategoryBookmark}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
  return result.json();
};

/**
 *  DELETE request with the following body:
 *  {
 *    "bookmarkId": number
 *  }
 * 
 *  returns:
*     {
        "msg": "Video categroy bookmark with ID ${id} has been successfully deleted!"
      }
 */
export const deleteVideoCategoryBookmarkBySubTitle = async (data) => {
  const result = await fetch(`${mainRoute.route}${path.videoCategoryBookmark}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
  return result.json();
};

/**
 * GET request 
 * 
 * childId, for which bookmarks are selected, is extracted on the back end from JWT
 * 
 * returns:
  *   {
        "data": [
          {
            "id": number,
            "childId": number,
            "actionTypeCategory": string,
            "createdAt": Date,
            "updatedAt": Date
          },
        ]
      }
 */
export const getAllVideoCategoryBookmarks = async () => {
  const result = await fetch(`${mainRoute.route}${path.videoCategoryBookmark}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
  return result.json();
};

export const createFavoriteJourney = async (data) => {
  const result = await fetch(`${mainRoute.route}${path.addFavoriteJourney}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
  return result.json();
};

export const getFavoriteJourneys = async () => {
  const result = await fetch(`${mainRoute.route}${path.getFavoriteJournerys}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
  return result.json();
};

export const deleteFavoriteBySubTitle = async (data) => {
  const result = await fetch(`${mainRoute.route}${path.deleteFavoriteJourney}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
  return result.json();
};


export const createHomework = async (data) => {
  const result = await fetch(`${mainRoute.route}${path.addHomework}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
  return result.json();
};

export const getHomework = async () => {
  const result = await fetch(`${mainRoute.route}${path.homework}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
  return result.json();
};

export const deleteHomework= async (data) => {
  const result = await fetch(`${mainRoute.route}${path.deleteHomework}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
  return result.json();
};

export const notifyHomework = async (id) => {
  const result = await fetch(`${mainRoute.route}${path.homeworkNotify}/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
  return result.json();
};

export const getContest = async (id) => {
  const result = await fetch(`${mainRoute.route}${path.contest}/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
  return result.json();
};

export const createContest = async (data) => {
  const result = await fetch(`${mainRoute.route}${path.contest}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
    body: JSON.stringify(data),
  });
  return result.json();
};

export const notifyContest = async (id) => {
  const result = await fetch(`${mainRoute.route}${path.contest}/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
  return result.json();
};

export const getContestByReference = async (id) => {
  const result = await fetch(`${mainRoute.route}${path.contest}/ref/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: cookies.get(CHILD_TOKEN),
    },
  });
  return result.json();
};