import React, { useState } from 'react';
import { useFormik } from 'formik';
import path from '../../../config.json';
import mainRoute from '../../../mainRoute';
import { message } from 'antd';
import { checkUnicValuesInBase, stripeSubscriptionForChildren } from '../../../api/api';
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { setUserDataStep3 } from '../../../Redux/signUpDataReducer.js';
import { useDispatch } from 'react-redux';
import { card2, card3, CARD_ELEMENT_OPTIONS } from '../../../constants/paymentTemplate';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import paymentSystems from '../../../asset/cards.webp';
import { ExtraInfo } from '../../commonComponents/PaymentComponents/ExtraInfo/ExtraInfo';
import styles from './Pay.module.sass';

function Step3({
  emailAndUsername,
  signUpData,
  total,
  userData,
  subscriptionPlayers,
  setIsPaymentStep,
}) {
  const { step3 } = 'signUpFieldValues;';
  const dispatch = useDispatch();
  const [toggPassConfirmCheckState, setToggPassConfirmCheckState] = useState(false);
  const [toggShowConfirmOrStripe, setToggShowConfirmOrStripe] = useState(false);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [stopSubmitBtn, setStopSubmitBtn] = useState(false);
  const [toggSubmitBtnSpinner, setToggSubmitBtnSpinner] = useState(false);
  const [stopApplyBtn, setStopApplyBtn] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 30, color: 'red', marginTop: '1.5rem', marginLeft: '9rem' }}
      spin
    />
  );

  useScrollToTop();

  const clearCardElements = (elementsToClear) => {
    const elementsArray = elementsToClear._elements;
    elementsArray.forEach((element) => {
      element.clear();
    });
  };

  const paymentErrorHandler = (errorMessage) => {
    setToggSubmitBtnSpinner(false);
    clearCardElements(elements);
    setToggPassConfirmCheckState(false);
    if (errorMessage) {
      message.error({
        content: errorMessage,
        duration: 6,
      });
    }
  };

  const stripeCheckoutHandler = (response) => {
    response.json().then(() => {
      setStopSubmitBtn(stopSubmitBtn);
      setToggSubmitBtnSpinner(false);
      clearCardElements(elements);
      message.success({
        content: 'Payment is success',
        duration: 6,
      });
    });
  };

  const unitSearchHandler = async (response) => {
    const responseJson = await response.json();
    if (responseJson.result === true) {
      setStopSubmitBtn(false);
      setToggShowConfirmOrStripe(!toggShowConfirmOrStripe);
      dispatch(
        setUserDataStep3({
          address: formik.values.address,
          country: formik.values.country,
          city: formik.values.city,
          region: formik.values.region,
          postalCode: formik.values.postalCode,
        }),
      );
      setToggPassConfirmCheckState(!toggPassConfirmCheckState);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          address: {
            country: formik.values.country,
            city: formik.values.city,
            state: formik.values.region,
            line1: formik.values.address,
            postal_code: formik.values.postalCode,
          },
          email: userData.userInfo.email,
          name: userData.userInfo.firstname,
        },
        card: elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),
      });

      if (!error) {
        const { id } = paymentMethod;
        try {
          const data = { ...signUpData, playersData: subscriptionPlayers };
          stripeSubscriptionForChildren({ id, data }).then((response) =>
            stripeCheckoutHandler(response),
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        paymentErrorHandler(error.message);
      }
    } else {
      setStopSubmitBtn(stopSubmitBtn);
      setToggSubmitBtnSpinner(false);
      message.error('Payment is not success');
    }
  };

  const formik = useFormik({
    initialValues: step3
      ? step3
      : {
          discountCoupon: '',
        },
    onSubmit: async () => {
      setToggSubmitBtnSpinner(true);
      if (!stopSubmitBtn) {
        try {
          checkUnicValuesInBase(emailAndUsername).then((response) => unitSearchHandler(response));
        } catch (e) {
          console.log(e);
        }
      }
    },
  });

  const submitCoupon = async (e) => {
    e.preventDefault();
    const stripeCus = signUpData.userData.stripeCus;
    const count = signUpData.userData.count;

    if (formik.values.discountCoupon) {
      setStopApplyBtn(!stopApplyBtn);
      try {
        const response = await fetch(`${mainRoute.route + path.signUpStep3Coupon}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ promocode: formik.values.discountCoupon, stripeCus, count }),
        });
        const responseJson = await response.json();
        try {
          if (responseJson.totalTarifs.subtotal === 0) {
            setSubTotalPrice('0000');
          } else {
            setSubTotalPrice(responseJson.totalTarifs.subtotal);
          }
        } catch (e) {
          console.log(e);
          message.error('This promo code is absent.');
        }
        if (responseJson.totalTarifs) {
          dispatch(setUserDataStep3({ promocode: formik.values.discountCoupon }));
          setStopApplyBtn(!stopApplyBtn);
        } else {
          setStopApplyBtn(stopApplyBtn);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleBackButtonClick = () => setIsPaymentStep(false);

  return (
    <form onSubmit={formik.handleSubmit} className={`${styles.payment_form} ${styles.form_mobile}`}>
      <h3 className={styles.payment_form__title}>Payment Details</h3>
      <div className={styles.container}>
        <p className={styles.container__description}>We accept the following cards:</p>
        <img src={paymentSystems} alt="Visa MasterCard Pictures" className={styles.pic_cards} />
      </div>
      <div className={styles.card_details_form}>
        <label className={styles.card_label}>
          Credit Card Number
          <CardNumberElement
            id="card-element"
            options={CARD_ELEMENT_OPTIONS}
            className={styles.card_number_input}
          />
        </label>
        <label className={styles.card_label}>
          Expiration
          <CardExpiryElement
            id="card-element-2"
            options={card2}
            className={styles.card_expiry_input}
          />
        </label>
        <label className={styles.card_label}>
          CVC
          <CardCvcElement id="card-element-3" options={card3} className={styles.card_cvc_input} />
        </label>
      </div>
      <div className={styles.container_coupon}>
        <label className={styles.discount_label}>
          Discount Coupon
          <input
            className={
              formik.touched.discountCoupon && formik.errors.discountCoupon
                ? styles[formik.errors.discountCoupon]
                : ''
            }
            name="discountCoupon"
            id="discountCoupon"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.discountCoupon.replace(/[\s]/g, '')}
          />
        </label>
        <button className={styles.grey_button} onClick={submitCoupon}>
          Apply
        </button>
      </div>
      <div className={styles.divTotal}>
        {subTotalPrice ? (
          <hr className={styles.totalPrice_hr} />
        ) : (
          <hr style={{ display: 'none' }} />
        )}
        <p className={styles.totalPrice}>
          <span>Total:</span>
          <span>${total / 100}</span>
        </p>
        {!!subTotalPrice && (
          <div className={styles.subPrice}>
            <span className={styles.subPrice_firstspan}>{subTotalPrice && 'Total with sale:'}</span>
            <span>{subTotalPrice && `$${subTotalPrice / 100}`}</span>
          </div>
        )}
      </div>
      <div className={styles.buttons}>
        <button
          type="button"
          disabled={toggPassConfirmCheckState}
          className={styles.bordered_button}
          onClick={handleBackButtonClick}
        >
          Back
        </button>
        {toggSubmitBtnSpinner ? (
          <Spin indicator={antIcon} />
        ) : (
          <button
            type="submit"
            disabled={toggPassConfirmCheckState}
            className={styles.buttonDefault}
          >
            Order Now
          </button>
        )}
      </div>
      <div className={styles.placingText}>
        <ExtraInfo />
      </div>
    </form>
  );
}

export default Step3;
