import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import styles from '../SupportForm/SupportForm.module.sass';
import Cookies from 'universal-cookie';
import mastercardimg from '../../../asset/step3img/mastercardimg.webp';
import visaimg from '../../../asset/step3img/visaimg.webp';
import countries from '../../Auth/SignUp/Steps/CountryList/ArrayCountry';
import * as Yup from 'yup';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { billingRequest, changeBillingRequest } from '../../../api/api';
import { message } from 'antd';

function ChangeBillingDetails(props) {
  const [showDetails, setShowDetails] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const cookies = new Cookies();
  const history = useHistory();
  const openShowDetails = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    try {
      billingRequest().then((response) => {
        response.json().then((result) => {
          props.setBillingDetails(result.billing_details, result.card, result.stripeCus);
        });
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const adress = props.billingDetails.billing_details.address;
  const billingCity = props.billingDetails.billing_details.address.city;
  const cards = props.billingDetails.card;
  const stripeCus = props.billingDetails.stripeCus;

  const card3 = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '10vw',
        '::placeholder': {
          fontSize: '10vw',
          color: '#aab7c4',
        },
      },
    },
  };

  const card2 = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '5vw',
        '::placeholder': {
          fontSize: '5vw',
          color: '#aab7c4',
        },
      },
    },
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '3vw',
        '::placeholder': {
          fontSize: '3vw',
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: 'US',
      postal_code: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required('required'),
      line1: Yup.string().trim().required('required'),
      line2: Yup.string().trim(),
      city: Yup.string().trim().required('required'),
      state: Yup.string().trim().required('required'),
      country: Yup.string().trim().required('required'),
      postal_code: Yup.string().trim().required('required'),
    }),

    onSubmit: async (values) => {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        billing_details: {
          address: {
            country: formik.values.country,
            city: formik.values.city,
            state: formik.values.state,
            line1: formik.values.line1,
            postal_code: formik.values.postal_code,
          },
          name: formik.values.name,
        },
        card: elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement),
      });

      if (!error) {
        const { id } = paymentMethod;
        try {
          changeBillingRequest(values, id, stripeCus).then((response) => {
            response.json().then((result) => {
              if (response.status === 200) {
                cookies.set('finaleToken', `${result.finaleToken}`, {
                  path: '/',
                  maxAge: 3600 * 30,
                });
              }
            });
          });
          message.success('Your Request has been sent!');
          history.push('/dashboard/account');
        } catch (error) {
          console.log(error);
        }
      } else {
        message.error('Payment is not success');
      }
    },
  });

  return (
    <div>
      {props.billingDetails ? (
        <>
          <section className={showDetails ? styles.none : styles.container}>
            <h2>Billing Details:</h2>
            <div className={styles.container__billing}>
              <div className={styles.container__billing_info}>
                <div>
                  <p>Card information:</p>
                  <div className={styles.container_cards}>
                    <div className={styles.card_images}>
                      {cards.brand.toLowerCase() === 'visa' ? (
                        <img src={visaimg} alt="visaimg" />
                      ) : cards.brand.toLowerCase() === 'mastercard' ? (
                        <img src={mastercardimg} alt="mastercardimg" />
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      <span>
                        {cards
                          ? `${cards.exp_month} / ${cards.exp_year},
                          ********* ${cards.last4},
                        ${cards.country}`
                          : ''}
                      </span>
                    </div>
                  </div>
                </div>

                <div className={styles.card_images}>
                  <p>Billing address:</p>
                  <p>
                    <span>
                      {adress
                        ? `${adress.line1},
                    ${adress.postal_code},
                    ${adress.state},
                    ${adress.city},
                    ${adress.country}`
                        : ''}
                    </span>
                  </p>
                </div>
              </div>
              <button onClick={openShowDetails} style={{ outline: 'none' }}>
                Change Your Billing Details
              </button>
            </div>
          </section>

          <main className={showDetails ? styles.main : styles.none}>
            <section>
              <form onSubmit={formik.handleSubmit}>
                <div className={styles.header}>
                  <h2>Change Billing Details</h2>
                  <p>Fields marked with * are mandatory</p>
                </div>
                <div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label htmlFor="name">Name on Card *</label>
                      <input
                        className={
                          formik.touched.name && formik.errors.name
                            ? styles[formik.errors.name]
                            : ''
                        }
                        type="text"
                        name="name"
                        id="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label htmlFor="line1">Address Line 1 *</label>
                      <input
                        className={
                          formik.touched.line1 && formik.errors.line1
                            ? styles[formik.errors.line1]
                            : ''
                        }
                        type="text"
                        name="line1"
                        id="line1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.line1}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label htmlFor="line2">Address Line 2</label>
                      <input
                        className={
                          formik.touched.line2 && formik.errors.line2
                            ? styles[formik.errors.line2]
                            : ''
                        }
                        type="text"
                        name="line2"
                        id="line2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.line2}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label htmlFor="city">City *</label>
                      <input
                        className={
                          formik.touched.city && formik.errors.city
                            ? styles[formik.errors.city]
                            : ''
                        }
                        type="text"
                        name="city"
                        id="city"
                        placeholder={billingCity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                      />
                    </div>
                    <div className={styles.col}>
                      <label htmlFor="state">State *</label>
                      <input
                        className={
                          formik.touched.state && formik.errors.state
                            ? styles[formik.errors.state]
                            : ''
                        }
                        type="text"
                        name="state"
                        id="state"
                        placeholder="NC"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <label htmlFor="country">Country *</label>
                      <select
                        className={
                          formik.touched.country && formik.errors.country
                            ? styles[formik.errors.country]
                            : ''
                        }
                        name="country"
                        id="country"
                        placeholder="US"
                        data-billing="data-billing"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                      >
                        {countries.map((item) => {
                          return (
                            <option key={item.name} value={item.code}>
                              {' '}
                              {item.name}{' '}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className={styles.col}>
                      <label htmlFor="postal_code">Postal Code *</label>
                      <input
                        className={
                          formik.touched.postal_code && formik.errors.postal_code
                            ? styles[formik.errors.postal_code]
                            : ''
                        }
                        type="text"
                        name="postal_code"
                        id="postal_code"
                        placeholder="27519"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.postal_code}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.parent_form}>
                  <label htmlFor="card-element" className={styles.cardNumText}>
                    Credit card number <span className={styles.redStar}>*</span>
                  </label>
                  <CardNumberElement
                    id="card-element"
                    options={CARD_ELEMENT_OPTIONS}
                    className={styles.card}
                  />

                  <div className={styles.cardContainer}>
                    <div className={styles.cardContainer1}>
                      <label htmlFor="card-element-2" className={styles.cardNumText}>
                        Expiration <span className={styles.redStar}>*</span>
                      </label>
                      <CardExpiryElement
                        id="card-element-2"
                        options={card2}
                        className={styles.card1}
                      />
                    </div>

                    <div className={styles.cardContainer1}>
                      <label htmlFor="card-element-3" className={styles.cardNumText2}>
                        CVC <span className={styles.redStar}>*</span>
                      </label>
                      <CardCvcElement
                        id="card-element-3"
                        options={card3}
                        className={styles.card2}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.row}>
                  <button type="submit" className={styles.submit}>
                    Submit
                  </button>
                  <button
                    type="button"
                    className={styles.bordered}
                    onClick={() => {
                      history.push('/dashboard/account');
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </section>
          </main>
        </>
      ) : (
        <div>
          <h2>Something goes wrong, please reload go page</h2>
        </div>
      )}
    </div>
  );
}

export default ChangeBillingDetails;
